import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { FobResponse } from "../../domain/calculator/fob-response.model";
import { TempResponse } from "../../domain/calculator/temp-response.model";
import { UserService } from "src/app/core/services/user/user.service";
import { UiService } from "../../services/ui/ui.service";
import { FobRequest } from "../../domain/calculator/fob-request.model";
import { TempRequest } from "../../domain/calculator/temp-request.model";


@Component({
    selector: 'calculator',
    templateUrl: './calculator.component.html',
    styleUrls: ['./calculator.component.css'],
    standalone: false
})
export class CalculatorComponent implements OnInit, OnDestroy {

  public fobForm: FormGroup;
  public fobWithinTolerance: boolean;
  public fobToleranceMessage: string;
  public fobResult: FobResponse = new FobResponse();
  fobCalc: number = 0;
  fobDifference: number = 0;
  toFMC = ["mda1", "mda2", "mda3", "mda4"];
  toFCU = ["msa1", "msa2", "gaalt"];
  tempCalc: TempResponse;

  public tempForm: FormGroup;
  public tempResponse: TempResponse;


  operatorSelected: string;
  enableOperator4C: boolean = false;
  checked: boolean = false;
  primary = '';

  constructor(
    private formBuilder: FormBuilder,
    private _user: UserService,
    private _ui: UiService
  ) { }

  ngOnInit(): void {
    this._ui.listenKeyboard('calculator-view');
    const minValue = -99999;
    const maxValue = 99999;

    this.fobForm = this.formBuilder.group({
      initialFob: [null, [Validators.required, Validators.max(maxValue)]],
      litersCharged: [null, [Validators.required, Validators.max(maxValue)]],
      density: [null, [Validators.max(maxValue)]],
      realFob: [null, [Validators.required, Validators.max(maxValue)]]
    });

    this.tempForm = this.formBuilder.group({
      adEle: [null, [Validators.required, Validators.min(minValue), Validators.max(maxValue)]],
      adTemp: [null, [Validators.required, Validators.min(minValue), Validators.max(maxValue)]],
      mda1: [null, [Validators.min(minValue), Validators.max(maxValue)]],
      mda2: [null, [Validators.min(minValue), Validators.max(maxValue)]],
      mda3: [null, [Validators.min(minValue), Validators.max(maxValue)]],
      mda4: [null, [Validators.min(minValue), Validators.max(maxValue)]],
      msa1: [null, [Validators.min(minValue), Validators.max(maxValue)]],
      msa2: [null, [Validators.min(minValue), Validators.max(maxValue)]],
      gaalt: [null, [Validators.min(minValue), Validators.max(maxValue)]],
      appMinimus: [null, [Validators.min(minValue), Validators.max(maxValue)]]
    });

    this.init();
  }

  ngOnDestroy(): void {
    this._ui.removeKeyboardListeners();
  }

  public calcFob() {
    let fob: FobRequest = new FobRequest(
      this.fobForm.get('initialFob').value,
      this.fobForm.get('litersCharged').value,
      this.fobForm.get('density').value,
      this.fobForm.get('realFob').value)
    if (this.checked && fob.density === null) {
      fob.density = 3.04;
    } else if (!this.checked && fob.density === null) {
      fob.density = 0.8;
    };
    this.fobCalc = Math.round(fob.initialFob + (fob.litersCharged * fob.density));
    this.fobDifference = Math.round(Math.abs(this.fobCalc - fob.realFob));

    this.fobResult.difference = this.fobDifference;
    this.fobResult.calc = this.fobCalc;


    if (fob.realFob <= 6000) {
      this.fobWithinTolerance = this.fobResult.difference <= 400;
    }
    else if (fob.realFob > 6000 && fob.realFob <= 12000) {
      this.fobWithinTolerance = this.fobResult.difference <= 500;
    }
    else if (fob.realFob > 12000) {
      this.fobWithinTolerance = this.fobResult.difference <= 600;
    }
  }

  calcTemp() {
    let temp: TempRequest = this.tempForm.getRawValue();
    for (const key in temp) {
      !temp[key] && this.tempForm.get(key).setValue(0);
    }

    let result = new TempResponse();
    let fieldColdTemp = Object.getOwnPropertyNames(temp);
    let adEle = temp.adEle;
    let adTemp = temp.adTemp;
    for (const fieldName of fieldColdTemp) {
      if (fieldName !== "adEle" && fieldName !== "adTemp") {
        result[fieldName] = this.calculateFields(temp[fieldName], adEle, adTemp, fieldName);
      }
    }
    this.tempResponse = result;
    this.tempResponse.calcDone = true;
  }


  calculateFields(value: number, adEle: number, adTemp: number, fieldName: string) {
    let data = {};
    let correction = this.calculateCorrection(value, adEle, adTemp);
    if (this.toFMC.includes(fieldName)) {
      let calculoFMC = 0;
      if (correction <= 0) {
        calculoFMC = Math.floor(correction / 10);
      } else {
        calculoFMC = Math.ceil(correction / 10);
      }
      correction = (calculoFMC * 10);
    } else if (this.toFCU.includes(fieldName)) {
      let calc = 0;
      if (correction <= 0) {
        calc = Math.floor(correction / 100);
      } else {
        calc = Math.ceil(correction / 100);
      }
      correction = (calc * 100);
    } else {
      correction = Math.round(correction);
    }
    data["value"] = value;
    data["correction"] = (value == 0 || !value) ? "N/A" : correction;
    data["toFCM"] = (value == 0 || !value) ? "N/A" : value + correction;
    return data;
  }

  calculateCorrection(value: number, adEle: number, adTemp: number) {
    const centigrade = 0.00198;
    let res = ((value - adEle) * ((15 - (adTemp + centigrade * adEle)) / (273 + (adTemp + centigrade * adEle) - (0.5 * centigrade * ((value - adEle) + adEle)))));
    return res;
  }

  cleanTempForm() {
    this.tempForm.reset();
    this.tempResponse = new TempResponse();
  }

  async init() {
    this.tempResponse = new TempResponse();
    let user = this._user.getUserValue;
    this.operatorSelected = user.profile.operator;
    if (this.operatorSelected === "4C") {
      this.enableOperator4C = true;
    }
  }

}