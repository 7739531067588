import { Injectable } from '@angular/core';
import { PilotConstants } from '../commons/pilot-constants';
import { environment } from '../../../../environments/environment';
import { Observable, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CrewInformationResponse } from '../domain/profile/crew-information-response.model';
import { MyFlights } from '../domain/profile/my-flights.model';
import { PilotProfileRequest } from '../domain/pilot-profile-request';
import { IProfile } from '../domain/user/user';
import { IDocumentsType, RequestSaveDocument, ResponseDocumentImage } from '../domain/profile/documents-pilot';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  apiUrl = environment.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private constants: PilotConstants,
  ) {}
  
  savePilotProfile(pilotProfileRequest: Partial<PilotProfileRequest>): Observable<any> {
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}`;
    return this.httpClient.post(URL, pilotProfileRequest);
  }

  getProfilePilot(): Observable<IProfile> {
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}`;
    return this.httpClient.get<IProfile>(URL);
  }
  
  getMyDocuments(): Observable<CrewInformationResponse> {
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}${this.constants.MY_DOCUMENTS}`;
    return this.httpClient.get<CrewInformationResponse>(URL);
  }

  getMyFlights(): Observable<MyFlights> {
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}${this.constants.MY_FLIGHTS}`;
    return this.httpClient.get<MyFlights>(URL);
  }

  updateBp(bpToChange: string): Observable<Response>{
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.BP_CONVERSION_BASE_PATH}${this.constants.BP_CONVERSION_UPDATE}${bpToChange}`;
    return this.httpClient.put<Response>(URL, null);
  }
  
  getDocumentInformation(file: Blob | File, typeDocument: IDocumentsType) {
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}/${typeDocument}`;
    let formData = new FormData();
    formData.append("file", file);
    return this.httpClient.post<ResponseDocumentImage>(URL, formData);
  }

  saveDocument(data: RequestSaveDocument, file: File | Blob, typeDocument: IDocumentsType) {
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}/ifn/${typeDocument}`;
    let formData = new FormData();
    formData.append("fileContent", file);
    formData.append("number", typeDocument == 'dni' ? data.run : data.number);
    formData.append("countryOfIssue", data.countryOfIssue?.toUpperCase());
    formData.append("issueDate", data.expirationDate);
    formData.append("expiryDate",data.expirationDate);
    if (typeDocument == 'visa') {
      formData.append("visaType", data.visaType);
      formData.append("efectiveDate", data.efectiveDate);
      formData.append("passportNumber", data.passportNumber);
      formData.append("placeOfIssue", data.nationality);
    }
    return this.httpClient.post(URL, formData);
  }

  async checkChatSpace(flightData: string) : Promise<any>{
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.API_CHAT}${this.constants.CHECK_CHAT_SPACE}${flightData}`;
    return await firstValueFrom(this.httpClient.get<boolean>(URL));
  }

  getQrCode() {
    const URL = `${this.apiUrl}${this.constants.BFF_PATH}${this.constants.PILOT_PROFILE_API}${this.constants.PILOT_PROFILE}` + `/qr`;
    return this.httpClient.post(URL, {});
  }

}
