import { Injectable } from '@angular/core';
import { IndexeddbService } from './indexeddb.service';
import { SafetyIndicatorsService } from './safety-indicators.service';
import * as moment from 'moment';
import { RolService } from './rol.service';
import { Router } from '@angular/router';
import { RosterCommon } from '../commons/roster-common';
import { UserService } from '../../../core/services/user/user.service';
import { IProfile } from '../domain/user/user';
import { PilotConstants } from '../commons/pilot-constants';


@Injectable({
  providedIn: 'root'
})
export class SetFuelSafetyService {

  current: number = 0;
  twuelveMonths;
  threeMonths;
  nextThirtyDays;
  currentMonthLastYear;
  currentMonth;
  profile;
  route;
  departure = 'allRoutes';
  arrival = 'allRoutes';
  optionRoute: string;


  constructor(protected indexeddbService: IndexeddbService,
    protected rosterCommon: RosterCommon,
    protected rolService: RolService,
    protected safetyIndicatorsService: SafetyIndicatorsService,
    public router: Router,
    private _user: UserService,
    private _constants: PilotConstants
  ) {}

  async setData(optionUpdate: string, optionRoute: string, onlyArrival = false) {
    this.optionRoute = optionRoute;
    let user = this._user.getUserValue;
    let route = await this.indexeddbService.getDataFromTable('profile', 'route');
    return new Promise<boolean>((resolve, reject) => {
      if (!onlyArrival) {
        this.indexeddbService.getDataFromTable('profile', 'departure').then(dep => {
          this.departure = dep;
          this.profile = user.profile;
          this.route = route;
          this.setDateRanges(optionUpdate).then(value => resolve(value));
        });
      } else {
        this.indexeddbService.getDataFromTable('profile', 'arrival').then(arr => {
          this.arrival = arr;
          this.profile = user.profile;
          this.route = route;
          this.setDateRanges(optionUpdate).then(value => resolve(value));
        });
      }
    });
  }

  setDateRanges(optionUpdate: string) {
    this.threeMonths = {
      startDate: moment().startOf('month').subtract(3, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    };

    this.currentMonth = {
      startDate: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    };

    this.currentMonthLastYear = {
      startDate: moment().startOf('month').subtract(1, 'years').subtract(1, 'months').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'years').subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    };

    this.twuelveMonths = {
      startDate: moment().startOf('month').subtract(12, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    };

    this.nextThirtyDays = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(30, 'days').format('YYYY-MM-DD')
    };

    return new Promise<boolean>((resolve, reject) => {
      this.callMethods(optionUpdate).then(value => resolve(value));
    });
  }

  callMethods(optionUpdate: string) {
    return new Promise<boolean>(async (resolve, reject) => {
      switch (optionUpdate) {
        case 'safety':
          await this.callandSetSafetyInformation(this.profile, this.twuelveMonths);
          resolve(true);
          break;
        default:
          break;
      }
    });
  }

  callandSetSafetyInformation(profile: IProfile, period): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let { fleet, operator } = profile;
      let data = this._constants.SET_DATA_INDICATORS;
      let quantityIndicators = Object.keys(data).length;
      let count = 0;
      for (const key in data) {
        let url = this.safetyIndicatorsService.getUrlEndpointIndicator(key, fleet, operator, period, data[key]?.['type']);
        this.safetyIndicatorsService.getDataSafety(url).subscribe({
          next: resp => {
            let respFleet = {};
            respFleet[this.profile.fleet] = resp;
            this.indexeddbService.addToTable('safety', respFleet, key );
            count++;
            // Validacion para determinar cuando se termina de llamar los servicios con la data para resolver promesa...
            if (quantityIndicators == count) resolve(true);
          }, 
          error: err => {
            count++;
            console.log(err);
            if (count == quantityIndicators) resolve(false);
          }
        });
      }
    });
  }

}
