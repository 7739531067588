import { MaintenixReport } from "./MaintenixReport";


export class MaintenixReportView{
    name: string;
    reports: Array<MaintenixReport>;

    constructor(name: string, reports: Array<MaintenixReport>){
        this.name = name;
        this.reports = reports;
      }
}

