import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DispatchService } from "src/app/modules/pilot/services/dispatch.service";

import * as moment from 'moment';
import jsPDF, { GState } from "jspdf";
import { SubcomponentState } from "src/app/modules/pilot/domain/dispatch/sub-component-state.model";
import { MaintenixReport } from "../../../domain/dispatch/maintenix/MaintenixReport";
import { MaintenixReportView } from '../../../domain/dispatch/maintenix/MaintenixReportView';

@Component({
    selector: 'maintenix-report',
    templateUrl: './maintenix-report.component.html',
    styleUrls: ['./maintenix-report.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MaintenixReportComponent implements OnInit, AfterContentChecked {

  @Input()
  public dispatch: boolean = false;

  public allMaintenixReports: Array<MaintenixReport> = [];

  falla: { deferralName: string, fallas: Array<MaintenixReport> };
  public reportToView: Array<any> = [];

  public groupNames: Array<string> = ["MEL A", "MEL B", "CDL-10CY", "MEL C", "MEL D", "NEF A", "NEF B", "NEF C", "NEF D", "NEF", "NEF 45", "NEF_PG_C", "NEF_PG_H",
    "NEF_PG_D", "NEF H", "NMEL", "CDL", "CDL-100H", "TLI-SHOR", "CDL-SRM", "TLI-LONG", "TLI-ECON", "TLI", "TLI-120D", "OTHER"];



  public maintenixMap: Map<number, MaintenixReportView>;

  loadingMR2Data: boolean = false;

  @Input()
  public tail: string = "";


  @Output() closeModalEvent = new EventEmitter();
  @Output() loadIsFinished = new EventEmitter<SubcomponentState>();

  @Output() reportFound = new EventEmitter<any>();


  constructor(
    private _dispatchService: DispatchService,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.init();
  }

  async init() {
    await this.buildEmptyMap();
    this.loadingMR2Data = true;
    this._dispatchService.getMaintenixReport(this.tail).subscribe({
      next: resp => {
        if (!resp || resp.length == 0) {
          this.loadIsFinished.emit(new SubcomponentState(this, 'not-available'));
          return;
        }

        this.parseAndCleanData(resp);
        this.mapData(resp);
        this.allMaintenixReports = resp;
        this.loadIsFinished.emit(new SubcomponentState(this, 'success'));
        this.loadingMR2Data = false;
      },
      error: err => {
        this.loadingMR2Data = false;
        console.error('Error at RouteReportComponent ngOnInit: ', err);
        this.loadIsFinished.emit(new SubcomponentState(this, 'error'));
      }
    });
  }


  closeModal() {
    this.closeModalEvent.emit();
  }

  private buildEmptyMap() {
    return new Promise<boolean>((resolve, reject) => {

      this.maintenixMap = new Map();

      this.maintenixMap.set(1, new MaintenixReportView("MEL A", new Array()));
      this.maintenixMap.set(2, new MaintenixReportView("MEL B", new Array()));
      this.maintenixMap.set(3, new MaintenixReportView("CDL-10CY", new Array()));
      this.maintenixMap.set(4, new MaintenixReportView("MEL C", new Array()));
      this.maintenixMap.set(5, new MaintenixReportView("MEL D", new Array()));
      this.maintenixMap.set(6, new MaintenixReportView("NEF A", new Array()));
      this.maintenixMap.set(7, new MaintenixReportView("NEF B", new Array()));
      this.maintenixMap.set(8, new MaintenixReportView("NEF C", new Array()));
      this.maintenixMap.set(9, new MaintenixReportView("NEF D", new Array()));
      this.maintenixMap.set(10, new MaintenixReportView("NEF", new Array()));
      this.maintenixMap.set(11, new MaintenixReportView("NEF 45", new Array()));
      this.maintenixMap.set(12, new MaintenixReportView("NEF_PG_C", new Array()));
      this.maintenixMap.set(13, new MaintenixReportView("NEF_PG_H", new Array()));
      this.maintenixMap.set(14, new MaintenixReportView("NEF_PG_D", new Array()));
      this.maintenixMap.set(15, new MaintenixReportView("NEF H", new Array()));
      this.maintenixMap.set(16, new MaintenixReportView("NMEL", new Array()));
      this.maintenixMap.set(17, new MaintenixReportView("CDL", new Array()));
      this.maintenixMap.set(18, new MaintenixReportView("CDL-100H", new Array()));
      this.maintenixMap.set(19, new MaintenixReportView("TLI-SHOR", new Array()));
      this.maintenixMap.set(20, new MaintenixReportView("CDL-SRM", new Array()));
      this.maintenixMap.set(21, new MaintenixReportView("TLI-LONG", new Array()));
      this.maintenixMap.set(22, new MaintenixReportView("TLI-ECON", new Array()));
      this.maintenixMap.set(23, new MaintenixReportView("TLI", new Array()));
      this.maintenixMap.set(24, new MaintenixReportView("TLI-120D", new Array()));
      this.maintenixMap.set(25, new MaintenixReportView("OTHER", new Array()));

      resolve(true);
    });


  }
  //crear un objeto que contenga la key y el array pero dentro de un mapa que contenga tambien el tipo de mel

  mapData(resp: Array<MaintenixReport>) {
    resp.forEach(item => {
      let getMap: MaintenixReportView = null;

      getMap = this.maintenixMap.get(item.orderPriority);

      let aa = getMap.reports;
      aa.push(item);
      getMap.reports = aa;
      this.maintenixMap.set(item.orderPriority, getMap);
    });
  }

  parseAndCleanData(reportList: MaintenixReport[]) {
    reportList?.forEach(report => {
      report.task.openDateLdt = moment(report.task.openDateLdt).format('DD-MMM-YYYY');
      report.task.dueDateLdt = moment(report.task.dueDateLdt).format('DD-MMM-YYYY');
      report.task.extendDueDateLdt = moment(report.task.extendDueDateLdt).format('DD-MMM-YYYY');


      report.task.name = report.task.name.replace(/[�]/g, '*');

    });
  }

  public getPDF(): string {

    let pdf = new jsPDF();

    let pdfWidth = pdf.internal.pageSize.getWidth();
    let pdfHeight = pdf.internal.pageSize.getHeight();


    let img = new Image()
    img.src = 'assets/img/navbar/logo-latam-pilot.jpg';
    pdf.addImage(img, 'png', pdfWidth - 40, 10, 30, 12);

    pdf.setFontSize(18);
    pdf.text('DEFERRED ITEM LIST', pdfWidth / 2, 15, { align: 'center' });

    pdf.setFontSize(12);
    pdf.text('(AIRCRAFT MR2 REPORT)', pdfWidth / 2, 20, { align: 'center' });

    let finalY;
    finalY = 39;

    pdf.text('Aircraft: ' + this.tail, 13, finalY);

    (pdf as any).autoTable({
      startY: finalY, startX: 0
    });

    let finalYforTable = 39;

    this.allMaintenixReports.forEach(report => {

      finalYforTable = (pdf as any).lastAutoTable.finalY;
      if(finalYforTable > 220){
        pdf.addPage();
        finalYforTable = 10;
      }

      let reportHeaderData = [report.task.name, report.task.tkBarcode];
      (pdf as any).autoTable({
        head: [],
        columnStyles: {
          valign: 'middle',
          halign: 'left', fontSize: 12, textColor: [255, 255, 255], fillColor: [27, 0, 136],
          0: { fontSize: 10, halign: 'center', valign: 'middle', textColor: [255, 255, 255], fillColor: [27, 0, 136], lineWidth: { top: 0, right: 0.3, bottom: 0, left: 0 } },
          1: { cellWidth: 47, halign: 'left', valign: 'middle', textColor: [255, 255, 255], fillColor: [27, 0, 136], lineWidth: { top: 0, right: 0.3, bottom: 0, left: 0.3 } }


        },
        startY: finalYforTable += 5, startX: -11,
        body: [reportHeaderData],
        theme: 'plain',
        margin: { right: 11, left: 11 }
      });

      let { dataMatrix, subTitleMatrix } = this.getMatrixData(report);

      (pdf as any).autoTable({
        head: [],
        startY: (pdf as any).lastAutoTable.finalY, startX: -11,
        body: subTitleMatrix,
        columnStyles: {
          0: { fontSize: 10, cellWidth: 62.6, halign: 'center', valign: 'middle' },
          1: { cellWidth: 62.6, halign: 'left', valign: 'middle' },
          2: { cellWidth: 62.6, halign: 'left', valign: 'middle' }

        },
        theme: 'plain',
        bodyStyles: {
          fontSize: 12, minCellWidth: 10,
          lineWidth: { top: 0.3, right: 0.3, bottom: 0.3, left: 0.3 }, textColor: [0, 0, 0], fillColor: [255, 255, 255], halign: 'center'
        },
        margin: { right: 11, left: 11 }

      });

      (pdf as any).autoTable({
        head: [],
        rowStyles: { 0: { cellHeight: 31.3, halign: 'center' } },
        columnStyles: {
          0: { cellWidth: 31.3, halign: 'center' },
          1: { cellWidth: 31.3, halign: 'center' },
          2: { cellWidth: 31.3, halign: 'center' },
          3: { cellWidth: 31.3, halign: 'center' },
          4: { cellWidth: 31.3, halign: 'center' },
          5: { cellWidth: 31.3, halign: 'center' },
          6: { cellWidth: 31.3, halign: 'center' },
          7: { cellWidth: 31.3, halign: 'center' }
        },
        startY: (pdf as any).lastAutoTable.finalY, startX: -11,
        body: dataMatrix,
        theme: 'plain',
        bodyStyles: {
          fontSize: 10, cellWidth: 15, lineWidth: { top: 0.3, right: 0.3, bottom: 0.3, left: 0.3 }, textColor: [0, 0, 0], fillColor: [255, 255, 255], valign: 'top',
          halign: 'center'
        },
        margin: { right: 0, left: 11 }
      });
    });
    let pageCount = pdf.internal.pages.length;

    for(let i =1; i<pageCount; i++){

      pdf.setPage(i);


      pdf.setGState(new GState({ opacity: 0.1 }));
      pdf.addImage(img, 'png', (pdfWidth / 2) - 50, (pdfHeight / 2) - 20, 100, 40);
      pdf.setGState(new GState({ opacity: 1 }));

      pdf.setFontSize(10);
      pdf.setTextColor('black')
      pdf.text("This document is generated exclusively by PILOT", pdfWidth / 2, pdfHeight - 10, { align: 'center' });
    }

    return pdf.output('datauristring').split(',')[1];
  }

  getMatrixData(report: MaintenixReport) {

    let deferral = report.fault.deferralClazz + "-" + report.fault.deferralReference;
    let limopsverification: { text: string, condition: boolean } = report.fault.failType == "LIMOPS" ? { text: "YES", condition: true } : { text: "NO", condition: false };
    let repetitiveInspection: { text: string, condition: boolean } = report.fault.repetitiveInspection == "YES" ? { text: "YES", condition: true } : { text: "NO", condition: false };

    let subTitleMatrix = [];
    subTitleMatrix[0] = new Array(6);
    subTitleMatrix[0][0] = deferral;
    subTitleMatrix[0][1] = "LIMOPS: " + limopsverification.text;
    subTitleMatrix[0][2] = "Rep. Inspection: " + repetitiveInspection.text;

    let dataMatrix = [];

    if (limopsverification.condition) {

      for (let i = 0; i < 6; i++) {
        dataMatrix[i] = new Array(7);
      }

      dataMatrix[0][0] = "Scheduling: ";
      dataMatrix[1][0] = "Found on Date: ";
      dataMatrix[2][0] = "Expiration Date: ";
      dataMatrix[3][0] = "Extend Expiration Date: ";


      dataMatrix[0][1] = null;
      dataMatrix[1][1] = report.task.openDateLdt;
      dataMatrix[2][1] = report.task.dueDateLdt;
      dataMatrix[3][1] = report.task.extendDueDateLdt;

      dataMatrix[0][2] = "Categoria Limops:";
      dataMatrix[1][2] = "ETOPS: ";
      dataMatrix[2][2] = "CAT: ";
      dataMatrix[3][2] = "RVSM: ";
      dataMatrix[4][2] = "RNAV: ";
      dataMatrix[5][2] = "RNP: ";

      dataMatrix[0][3] = report.fault.faultTypes;
      dataMatrix[1][3] = report.fault.etops;
      dataMatrix[2][3] = report.fault.cat;
      dataMatrix[3][3] = report.fault.rvsm;
      dataMatrix[4][3] = report.fault.rnav;
      dataMatrix[5][3] = report.fault.rnp;
    } else {

      for (let i = 0; i < 4; i++) {
        dataMatrix[i] = new Array(7);
      }

      dataMatrix[0][0] = "Scheduling: ";
      dataMatrix[1][0] = "Found on Date: ";
      dataMatrix[2][0] = "Expiration Date: ";
      dataMatrix[3][0] = "Extend Expiration Date: ";


      dataMatrix[0][1] = report.task.scheduling.interval + " " +report.task.scheduling.dataType;
      dataMatrix[1][1] = report.task.openDateLdt;
      dataMatrix[2][1] = report.task.dueDateLdt;
      dataMatrix[3][1] = report.task.extendDueDateLdt;

      dataMatrix[0][2] = "";
      dataMatrix[1][2] = "";
      dataMatrix[2][2] = "";
      
      dataMatrix[0][3] = "";
      dataMatrix[1][3] = "";
      dataMatrix[2][3] = "";
    }

    if (repetitiveInspection.condition) {

      dataMatrix[0][4] = "Action Required:";
      dataMatrix[1][4] = "Description: ";

      dataMatrix[0][5] = report.fault.actionRequired;
      dataMatrix[1][5] = " ";
    } else {

      dataMatrix[0][4] = "";
      dataMatrix[1][4] = "";

      dataMatrix[0][5] = "";
      dataMatrix[1][5] = "";
    }

    return { dataMatrix, subTitleMatrix };
  }

}