import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DispatchService } from 'src/app/modules/pilot/services/dispatch.service';
import { ActivatedRoute, Params} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { finalize } from "rxjs/operators";
import { MessageDispatchDialogComponent } from './message-dispatch-dialog/message-dispatch-dialog.component';
import { LoadSheet } from 'src/app/modules/pilot/domain/dispatch/loadsheet.model';
import { SimpleDialog } from 'src/app/core/services/dialog/simple-dialog.service';
import { FlightId } from 'src/app/modules/pilot/domain/flight-id.model';
import { CommonsService } from 'src/app/core/services/commons/commons.service';
import { DispatchDocument } from 'src/app/modules/pilot/domain/dispatch/dispatch-document.model';
import { JoinDocsRequest } from 'src/app/modules/pilot/domain/dispatch/join-docs-request.model';
import { DispatchSubcomponentManager } from './dispatch-subcomponent-manager.component';
import { SubcomponentState } from 'src/app/modules/pilot/domain/dispatch/sub-component-state.model';
import { Activity } from 'src/app/modules/pilot/domain/roster/activity.model';
import { UserService } from 'src/app/core/services/user/user.service';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { IndexeddbService } from 'src/app/modules/pilot/services/indexeddb.service';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { DispatchPDF } from 'src/app/modules/pilot/domain/dispatch/dispatch-pdf.model';
import { FeedbackModalComponent } from '../modals/feedback-modal/feedback-modal.component';
import { IUser } from 'src/app/modules/pilot/domain/user/user';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import { ILoadSheetByFlight } from 'src/app/modules/pilot/domain/loadsheet/loadsheet-by-flight.interface';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { DispatchZFWRequest } from 'src/app/modules/pilot/domain/dispatch/DispatchZFWRequest';
import { DispatchZFWResponse } from 'src/app/modules/pilot/domain/dispatch/DispatchZFWResponse';
import { environment } from 'src/environments/environment';
import { CaeStatusEnum, LoadSheetStatusEnum } from '../../domain/dispatch/status-cae.enum';
import { fetchPDF, confirmAlert } from 'src/app/core/utils/utils';
import { SeatsByFlightResponse } from '../../domain/dispatch/SeatsByFlightResponse';
import { LoadSheetsComparedACK } from '../../domain/dispatch/cosmos/compare-ack-request';
import { CompareResponse } from '../../domain/dispatch/cosmos/compare-response';

@Component({
    selector: 'dispatch-folder',
    templateUrl: './dispatch.component.html',
    styleUrls: ['./dispatch.component.css'],
    standalone: false
})
export class DispatchComponent implements OnInit, OnDestroy {

  caeStatus = CaeStatusEnum;
  loadsheetStatus = LoadSheetStatusEnum;

  @ViewChild(MessageDispatchDialogComponent) loadsheetComponent:MessageDispatchDialogComponent;

  //Dispatch documents
  public dispatchFolder: Map<string, Map<string, DispatchDocument>>;

  public subcomponentManager: DispatchSubcomponentManager;

  //Loadsheeet
  public loadsheet: LoadSheet;
  public listLoadSheet: LoadSheet[] = new Array();
  public loadsheetState: string;
  public loadsheetViewDoc: any;
  public allowACK:boolean = false;
  public allowLMC:boolean = false;
  public hideZFW: boolean = true;


  //Flight id
  @Input()
  public flight: FlightId;

  @Input()
  public anotherDispatch: boolean = false;

  @Input()
  public seatsByFlightReady: SeatsByFlightResponse= new SeatsByFlightResponse();

  @Output() flightWasFound = new EventEmitter<any>();

  //Others
  public downloadAllIsSelected: boolean = false;
  public onSearchMode: boolean;
  public onSearchModeLoading: boolean = true;
  public downloading: boolean;
  public dispatchFolderHasErrors: boolean;
  public dispatchLastUpdate: string;
  public caeDocsLoad: string = "Loading";
  public caeDocsError: boolean = false;
  public caeDocsErrorMessage: string = "";

  public flightInfo: string = "";
  public flightInfoZFW: string = "";
  public flightInfoDate: string = "";
  public compareResponse: CompareResponse;
  public activateCosmosCompare: boolean =false;
  public versionLS: number;
  public seats: SeatsByFlightResponse= new SeatsByFlightResponse();


  public dialogRef: any;

  user: IUser;
  userSubs: Subscription;

  useFulLinks = this.constants.OTHERS_USEFUL_LINKS;

  hiddenGESOperators = ["4C", "JJ"];

  // ocultar mensajes para operadores que utilizan despacho de pilot como oficial
  hiddenDisclaimerDispatchFolderForOperators = this.constants.OFICIAL_DISPATCH_OPERATORS;
  hiddenDisclaimerLoadSheetForOperators = this.constants.OFICIAL_LOADSHEET_OPERATORS;
  showDisclaimerDispatchDocuments: boolean = true;
  showDisclaimerLoadSheet: boolean = true;
  refreshSubs: Subscription;

  isConnected: boolean;
  isTestEnvironment: boolean = !environment.production;

  documentsUnavailableMessage: string = "Documents unavailable. Try later.";
  allowsGroupTestMR2: boolean = false;

  constructor(
    private _dispatchService: DispatchService,
    private _route: ActivatedRoute,
    public dialog: MatDialog,
    protected simpleDialog: SimpleDialog,
    private commonsService:CommonsService,
    private _user: UserService,
    private _preferences: PreferencesService,
    private platform: Platform,
    private indexeddb: IndexeddbService,
    public constants: PilotConstants,
    private _ui: UiService,
    public _network: NetworkService,
  ) {}

  ngOnInit() {
    this.refreshSubs = this._ui.getStateRefresh().subscribe( state => {
      if (state.refresh)  {
        this.refreshAll(true);
      };
    });
    this.userSubs = this._user.getUser().subscribe((user) => { 
      this.user = user;
      this.allowsGroupTestMR2 = user?.roles?.includes('PILOT_TEST_USER');
    });
    this.init();
  }

  ngOnDestroy(): void {
    this.userSubs?.unsubscribe();
    this.refreshSubs?.unsubscribe();
  }

  async init() {
    
    this.valideNetwork();
    this.commonsService.registryEvent({ category: 'dispatch_folder', event: "dispatch_folder_main_page"});
    this.dispatchLastUpdate = '...';
    this.subcomponentManager = new DispatchSubcomponentManager(this.simpleDialog, this.commonsService, this._user);

    this.buildEmptyFolder();

    if(!this.flight){
      this.onSearchMode = false;
      this._route.queryParams.subscribe((params: Params) => {
        let { operator, tail, utcDate, aircraftSubtype, flightNumber, departureIataCode, arrivalIataCode, localDate, carrierOperating, dateOfFlight} = params;
        this.flight = new FlightId({
          flightNumber, 
          tail, 
          operator, 
          departureIataCode, 
          aircraftSubtype, 
          arrivalIataCode, 
          carrierOperating,
          dateOfFlight,
          flightDate: utcDate, 
          localFlightDate: localDate, 
        });
        
        this.showDisclaimerDispatchDocuments = !this.hiddenDisclaimerDispatchFolderForOperators.includes(this.flight.carrierOperating);
        this.showDisclaimerLoadSheet = !this.hiddenDisclaimerLoadSheetForOperators.includes(this.flight.carrierOperating);
    
        //Recovering dispatch id if it exists
        this.indexeddb.getDataFromTable('rol', 'activities').then((response: Array<Activity>) => {
          this.flight.dispatchId = response?.map(f => Object.assign(new Activity(), f)).filter(f => f.equalFlight(flightNumber, departureIataCode, moment(utcDate).format('YYYY-MM-DD'), operator))[0]?.dispatchId;
          this.flightInfo = `${ carrierOperating + " "+ flightNumber } ${ departureIataCode }-${ arrivalIataCode } ${tail}`;
          this.getLoadsheet();
          this.onSearchModeLoading = false;
          this.loadDispatchDocs(this.flight.dispatchId ? true : false);
          this._preferences.setItem('last-dispatch-update', moment().utc().format('DD/MMM/YY HH:mm'));
          this._preferences.getItem('last-dispatch-update').then( value => this.dispatchLastUpdate = value);
          this.getZFW();
          this.flightInfoDate = `${moment(this.flight.flightDate).format('DD/MM/YYYY')}`
        });
      });
    } else{
      this.onSearchMode = true;
    }
  }

  async valideNetwork () {
    this.isConnected = await this._network.getStatusNetwork();
    this._network.getState().subscribe(isConnected => this.isConnected = isConnected);
  }

  refreshAll(refresh: boolean = false){
    this.commonsService.registryEvent({ category: 'dispatch_folder', event: "update_documents"});
    this.buildEmptyFolder();
    this.getLoadsheet();
    this.onSearchModeLoading = false;
    this.loadDispatchDocs(false, refresh);
    this.getZFW();

    this.subcomponentManager.reloadBTMReport();
    this.subcomponentManager.reloadMaintenixReport();
    this.subcomponentManager.reloadDirectosReport();
    //this.subcomponentManager.refreshLimops();
    //this.subcomponentManager.refreshGroundSupportStatus();
    this._preferences.setItem('last-dispatch-update', moment().utc().format('DD/MMM/YY HH:mm'));
    this._preferences.getItem('last-dispatch-update').then( value => this.dispatchLastUpdate = value);
    
}

  /* ---------- PDF Documents management ---------- */

  private buildEmptyFolder(){
    this.dispatchFolder = new Map();
    this.dispatchFolder.set('Maintenance condition extra', new Map());
    this.dispatchFolder.get('Maintenance condition extra').set('Limops', new DispatchDocument("Limops"));
    this.dispatchFolder.get('Maintenance condition extra').set('Maintenix', new DispatchDocument("Maintenix"));
    
    this.dispatchFolder.set('Briefing extra', new Map());
    this.dispatchFolder.get('Briefing extra').set('BTM', new DispatchDocument("BTM"));

    this.dispatchFolder.set('Other documents extra', new Map());
    this.dispatchFolder.get('Other documents extra').set('Route Report', new DispatchDocument("Route Report"));
    this.dispatchFolder.get('Other documents extra').set('Ground Support Status', new DispatchDocument("Ground Support Status"));

    this.dispatchFolder.set('Briefing', new Map());
    this.dispatchFolder.set('Maintenance condition', new Map());
    this.dispatchFolder.set('Flight plan', new Map());
    this.dispatchFolder.set('NOTAM', new Map());
    this.dispatchFolder.set('Other documents', new Map());
    this.dispatchFolder.set('Weather', new Map());

    this.dispatchFolder.set('Operational recommendations', new Map());
    this.dispatchFolder.get('Operational recommendations').set('Directos', new DispatchDocument("Directos"));

  }

  // Validar si el vuelo se encuentra dentro de las proximas 24 horas
  validateNext24Hours(date: string) {
    return new Promise<boolean>((resolve, reject) => {
      const currentDate = moment().utc();
      const flightDate = moment(date);
      const difference = currentDate.diff(flightDate, 'hours');
      if (flightDate.isAfter(currentDate)) {
        resolve(Math.abs(difference) <= 24);
      }
      resolve(true);
    })
  }

  private async loadDispatchDocs(tryAgain: boolean = false, refresh = false) {
    let isValid = await this.validateNext24Hours(this.flight.flightDate);
    if (!isValid) {
      console.log("The difference exceeds 24 hours");
      this.caeDocsLoad = "NotFound";
      this.caeDocsError = true;
      this.caeDocsErrorMessage = this.documentsUnavailableMessage;
      return
    };
    this.caeDocsLoad = "Loading";

    const dispatchService = this.flight.dispatchId ? 
      this._dispatchService.getCaeDocsByFlightId(this.flight) : 
      this._dispatchService.getCaeDocsByFlight(this.flight, this.anotherDispatch);
    this.dispatchFolderHasErrors = false;
    dispatchService.subscribe({
      next: (resp) => {
        
        this.handleNextLoadDispatchDocs(resp, refresh);
      },
      error: error => {
        if (tryAgain) {
          this.loadDispatchDocs();
        } else {
          console.error("Error at loadDispatchDocs: ", error);
          if (error?.status == 403) {
            this.caeDocsLoad = this.caeStatus.FORBIDDEN;
            return;
          }
          this.dispatchFolderHasErrors = true;
          this.caeDocsLoad = "Error";
          this.caeDocsError = true;
          this.caeDocsErrorMessage = this.documentsUnavailableMessage;
        }
        refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
      }
    });
  }

  handleNextLoadDispatchDocs(resp: Map<string, Map<string, string>>, refresh: boolean) {
    this.caeDocsError = resp?.['hasError'];
    this.caeDocsErrorMessage = resp?.['messageError'];

    Object.keys(resp).forEach(docType => {
      if (!["hasError", "messageError"].includes(docType)) {
        this.dispatchFolder.set(docType, new Map());
        if (Object.keys(resp[docType]).length == 0) {
          this.caeDocsLoad = "NotFound";
          this.caeDocsError = true;
          this.caeDocsErrorMessage = resp?.['messageError'] ?? this.documentsUnavailableMessage;
        }
        Object.keys(resp[docType]).forEach(docName => {
          this.dispatchFolder.get(docType).set(docName, new DispatchDocument(docName, resp[docType][docName]));
          this.caeDocsLoad = "Success";
        });
      }
    });
    refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
  }

  async download(){
   let selectedDocs: Array<DispatchDocument> = new Array();
  
    //Leaving only dispatch documents
    this.dispatchFolder.forEach(docType =>
      docType.forEach(doc => {
        if (this.hiddenGESOperators.includes(this.flight.carrierOperating) && doc.name == "Ground Support Status") return;
        selectedDocs.push(doc);
      })
    );

    let selectedFrontDocs = !this.downloadAllIsSelected ? selectedDocs.filter(doc => doc.id == null && doc.isSelected) : selectedDocs.filter(doc => doc.id == null);
    selectedDocs = !this.downloadAllIsSelected ? selectedDocs.filter(doc => doc.id != null && doc.isSelected) : selectedDocs.filter(doc => doc.id != null);

    if(selectedFrontDocs.length == 0 && selectedDocs.length == 0 && !this.downloadAllIsSelected){
      confirmAlert({
        icon: 'info',
        title: 'You have not selected any document',
        text: 'There must be at least one document in selection',
        showCancelButton: false,
        confirmButtonText: "OK"
      }, true);
      return;
    }

    this.downloading = true;

    const frontDocsFile: File = this.createFile(selectedFrontDocs);
    let signedUrl = '';

    await this._dispatchService.getSignedUrl().then(
      signedUrlResp => signedUrl = signedUrlResp,
      getSignedUrlError => console.log("Error at getting bucket url: ", getSignedUrlError)
    );
    this.commonsService.registryEvent({ category: 'download_dispatch_folder', event: "dispatch_folder_main_page"});
    this._dispatchService.putFileOnBucket(frontDocsFile, signedUrl)
      .pipe(finalize(() => {
        this._dispatchService.joinDocs(new JoinDocsRequest(signedUrl.split('dispatch-documents/')[1].split('?X-Goog')[0], selectedDocs))
        .pipe(finalize(() => this.downloading = false))
        .subscribe({
          next: (dispatchPdf: DispatchPDF)  => {
            if(dispatchPdf.docsWithError){
              Swal.fire({
                icon: 'warning',
                title: 'Some documents could not be downloaded',
                html: '<span style="font-weight: bold">' + dispatchPdf.docsWithError + 
                '</span> cannot be downloaded. You can download the documents one by one or try downloading the entire dispatch folder again '
              });
            }
            this.commonsService.registryEvent({ category: 'dispatch_folder', event: "success_download_dispatch_folder"});
            this.showPdf(dispatchPdf.pdf);
          },
          error: error => {
            this.commonsService.registryEvent({ category: 'dispatch_folder', event: "error_download_dispatch_folder"});
            console.error("Error at downloadSelectedDocs: ", error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'There was a problem downloading your documents'
            });
          }
        });
      }))
      .subscribe({
        next: () => {}, 
        error:putFileError => console.log("Error at uploading file to bucket: ", putFileError)
      });

    this.simpleDialog.snackBarMessage('Your documents are coming');
  }

  private showPdf(base64){
    let name = moment(this.flight.flightDate).format('YYYYMMDD') + '-' + this.flight.operator + '-' + this.flight.flightNumber + '-' + this.flight.departureIataCode +'.pdf';
    fetchPDF(base64, name, this.platform.is('hybrid'));
  }

  public completeFlightWasFound(flight: Activity){
    if(!flight){
      this.flightWasFound.emit(false);
      return;
    }

    this.flight.arrivalIataCode = flight.flightLeg.arrival.iataCode;
    this.flight.tail=flight.flightLeg.aircraftRegistration;
    this.flight.aircraftSubtype= flight.flightLeg.fleet;
    this.flight.carrierOperating = flight.flightLeg.airlineCodes.operating;

    this.onSearchModeLoading = false;
    
    if(this.onSearchMode){
      this.loadDispatchDocs();
      this.flightWasFound.emit(true);
    }
  }

  /* ---------- Loadsheet ---------- */
  async openLoadSheetModal(version: number) {
    this.commonsService.registryEvent({ category: 'dispatch_folder', event: "open_loadsheet_df"});
    this.allowACK = false;
    this.listLoadSheet.forEach(docto => {
      if (docto.version == version) {
        if (docto.version == this.listLoadSheet.length) {
          this.allowACK = true;
          this.allowLMC = true;
        }
        this.loadsheetViewDoc = docto;
        this.loadsheetViewDoc.fleet = this.flight.aircraftSubtype;
       
      }
    });
    this.versionLS = version; 
    if(this.listLoadSheet.length>=2){
      this.activateCosmosCompare=true;
    }  

    let { role } = await this._dispatchService.showLoadSheetModal({
        loadSheet: this.loadsheetViewDoc,
        allowACK: this.allowACK,
        allowLMC: this.allowLMC,
        isConnected: this.isConnected,
        listLoadSheet: this.listLoadSheet,
        activateCosmosCompare: this.activateCosmosCompare,
        versionLS: this.versionLS
    });
    this.activateCosmosCompare=false;
    if (role === 'close') {
      this.getLoadsheet();
    }
  }

  public getLoadsheet() {
    this.loadsheetState = 'waiting';
    let { localFlightDate, flightNumber, carrierOperating, departureIataCode, arrivalIataCode, flightDate, tail, dateOfFlight } = this.flight;
    let flightRequest: ILoadSheetByFlight = {
      flightDate: localFlightDate,
      operator: carrierOperating,
      flightNumber,
      departureIataCode,
      arrivalIataCode,
      tail,
      dateOfFlight,
      flightDateUtc: flightDate
    }

    this._dispatchService.getLoadSheet(flightRequest).subscribe({
      next: listLoadsheets => {
        if(listLoadsheets && listLoadsheets.length > 0){
          
          this.listLoadSheet = listLoadsheets;
          let cont: number = 0;
          this.listLoadSheet.forEach(docto => {
            cont++;
            docto.version = cont;
          });
        }

        this.loadsheetState = Array.isArray(listLoadsheets)? "success": 'error';
      },
      error: error => {

        console.error("Error at getLoadsheet: ", error);
        if (error?.status == 403) {
          this.loadsheetState = this.loadsheetStatus.FORBIDDEN;
          return;
        }
        this.loadsheetState = 'error';
      }
    });
    
  }

  createFile(content: Array<DispatchDocument>): File{
    let blob: Blob = new Blob([JSON.stringify(content)], {type: 'text/plain'});
    let arrayOfBlob = new Array<Blob>();
    arrayOfBlob.push(blob);
    return new File(arrayOfBlob, "documents.json", {type: 'text/plain'});
  }

  public routeReportPDFIsReady(pdfState: any){
    this.subcomponentManager.routeReport.setState(pdfState);
    if(this.subcomponentManager.routeReport.success()){
      this.dispatchFolder.get("Other documents extra").set("Route Report", new DispatchDocument("Route Report", null, this.subcomponentManager.routeReport.getSubcomponent().getPDF()));
    }
  }

  public btmPDFIsReady(btmComponent: SubcomponentState){
    this.subcomponentManager.briefing.setSubcomponent(btmComponent.subcomponent);

    //Successful btm report
    if(btmComponent.success()){

      this.subcomponentManager.briefing.getSubcomponent().getPDF()
      .then(pdf => this.dispatchFolder.get("Briefing extra").set("BTM", new DispatchDocument("BTM", null, pdf.output('datauristring').split(',')[1]))
      )
      .catch(err => {
        this.subcomponentManager.briefing.errorMessage();
        console.error("Error at btmPDFIsReady: ", err)
      })
      .finally(() => {
        this.subcomponentManager.briefing.hideTheModal();
        this.subcomponentManager.briefing.setState(btmComponent.state);
      });
    }
    else if(btmComponent.notAvailable()){
      this.subcomponentManager.briefing.notAvailableMessage();
      this.subcomponentManager.briefing.setState(btmComponent.state);
    }
    else{
      this.subcomponentManager.briefing.errorMessage();
      this.subcomponentManager.briefing.setState(btmComponent.state);
    }
  }

  seatsByFlightIsReady(resp: SeatsByFlightResponse){
    this.seats=resp;
  }

  public limopsReportPDFIsReady(limopsComponent: SubcomponentState){
    this.subcomponentManager.limops.setState(limopsComponent.state);
    this.subcomponentManager.limops.setSubcomponent(limopsComponent.subcomponent);
    if(this.subcomponentManager.limops.success()){
      this.dispatchFolder.get('Maintenance condition extra').set("Limops", new DispatchDocument("Limops", null, this.subcomponentManager.limops.getSubcomponent().getPDF()))
    }
    else if(this.subcomponentManager.limops.hasError()){(
      this.subcomponentManager.limops.errorMessage());
    }
    else{
      this.subcomponentManager.limops.setAlertMessage('No operational limitations');
    }
  }

  public groundSupportStatusIsReady(gss: SubcomponentState){
    
    this.subcomponentManager.groundSupportStatus.setState(gss.state);
    this.subcomponentManager.groundSupportStatus.setSubcomponent(gss.subcomponent);
    if(this.subcomponentManager.groundSupportStatus.success()){
      this.dispatchFolder.get('Other documents extra').set("Ground Support Status", new DispatchDocument("Ground Support Status", null, this.subcomponentManager.groundSupportStatus.getSubcomponent().getPDF()));
    }
    else if(this.subcomponentManager.groundSupportStatus.hasError()){
      this.subcomponentManager.groundSupportStatus.errorMessage();
    }
    else{
      this.subcomponentManager.groundSupportStatus.notAvailableMessage();
    }

  }

  feedback() {
    this.dialogRef = this.dialog.open(FeedbackModalComponent, {
      width: '600px',
      maxWidth: '95vw',
      data: {
        message: "Feedback",
        operator: this.flight.operator,
        flightNumber: this.flight.flightNumber,
        flightDate: this.flight.flightDate,
        departureIataCode: this.flight.departureIataCode
      },
      disableClose: true,
      id: "modal-feedback-dispatch"
    });
  }
  
  public getZFW() {

    if (!this.isTestEnvironment) return;

    let flightZFWRequest = new DispatchZFWRequest();
    flightZFWRequest.airlineCode = this.flight.operator;
    flightZFWRequest.carrierOperating = this.flight.carrierOperating;
    flightZFWRequest.dateFlight = this.flight.flightDate;
    flightZFWRequest.departure = this.flight.departureIataCode;
    flightZFWRequest.arrival = this.flight.arrivalIataCode;
    flightZFWRequest.fleet = this.flight.aircraftSubtype;

    flightZFWRequest.flightNumber = this.flight.flightNumber.toString();

    this._dispatchService.getZFW(flightZFWRequest).subscribe({
      next: (resp: DispatchZFWResponse ) => this.setZWF(resp), 
      error: (error) => {
        console.log("Error at getting ZFW: ", error);
        this.hideZFW = true;
      }
    });
  }

  setZWF(data: DispatchZFWResponse) {
    if (data?.zfw) {
      let zfw = data.zfw.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.flightInfoZFW = `${zfw}` + " KG";
      setTimeout(() => {
        this.hideZFW = true;

      }, data.timeMillisInView);
      this.hideZFW = false;
    } else {
      this.hideZFW = true;
    }
  }


  setCompareAck(id: number){
    let compareAckRequest = new LoadSheetsComparedACK();
    compareAckRequest.id = id;
    compareAckRequest.usefulComparison = 0;
    compareAckRequest.pilotCommentary = "";
    this._dispatchService.setCompareACK(compareAckRequest).subscribe({
        next: (response) => {
        console.log(response);
        },
        error: error => {
          console.log("Error: " + error);
        }
      });
  }

  

  
  public maintenixIsReady(main: any){
    
    this.subcomponentManager.maintenix.setState(main.state);
    this.subcomponentManager.maintenix.setSubcomponent(main.subcomponent);
    if(this.subcomponentManager.maintenix.success()){
      this.dispatchFolder.get('Maintenance condition extra').set("Maintenix", new DispatchDocument("Maintenix", null, this.subcomponentManager.maintenix.getSubcomponent().getPDF()));
    }
    else if(this.subcomponentManager.maintenix.hasError()){
      this.subcomponentManager.maintenix.errorMessage();
    }
    else{
      this.subcomponentManager.maintenix.notAvailableMessage();
    }

  }

  public directosIsReady(main: any){
    
    this.subcomponentManager.directos.setState(main.state);
    this.subcomponentManager.directos.setSubcomponent(main.subcomponent);
    if(this.subcomponentManager.directos.success()){
      this.dispatchFolder.get('Operational recommendations').set("Directos", new DispatchDocument("Directos", null, this.subcomponentManager.directos.getSubcomponent().getPDF()));
    }
    else if(this.subcomponentManager.directos.hasError()){
      this.subcomponentManager.directos.errorMessage();
    }
    else{
      this.subcomponentManager.directos.notAvailableMessage();
    }

  }
}