import { Component, OnInit, Input, SimpleChanges, HostListener, OnChanges, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SetFuelSafetyService } from '../../../services/set-fuel-safety.service';
import * as moment from 'moment';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { Activity } from 'src/app/modules/pilot/domain/roster/activity.model';
import Swal from 'sweetalert2';

@Component({
    selector: 'roster-fcard',
    templateUrl: './roster-fcard.component.html',
    styleUrls: ['./roster-fcard.component.css', '../rol.component.css'],
    standalone: false
})
export class RosterFcard implements OnInit, OnChanges{

  @Input() origin: string;
  @Input('activity') activity: Activity;
  @Input('nextFlight') nextFlight: any;
  @Input('grayCard') grayCard: boolean;


  @Input('isFromBrasil') isFromBrasil: any;
  @Input('checked') checked: any;

  @Output() searchFlightInformation = new EventEmitter();

  innerWidth: any;
  flagInformation: boolean = false;

  localDateEndsTomorrow: boolean = false;
  utcDateEndsTomorrow: boolean = false;
  brasiliaDateEndsTomorrow: boolean = false;

  panelIsOpen: boolean = false;

  incompleteFlight: boolean = false;

  @ViewChild('expansionPanel') expansionPanel: ElementRef<HTMLInputElement>;

  constructor(
    public router: Router,
    protected setFuelSafetyService: SetFuelSafetyService,
    protected _network: NetworkService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.incompleteFlight = this.activity?.flightLeg?.operator == null;

    if(this.incompleteFlight){
      this.fillIncompleteFlight();
    }
    else{
      if(this.router.url == "/flightInformation"){
        this.flagInformation = true;
      }
  
      this.calculateDiff();
  
    }
  }

  calculateDiff() {
    if(!this.activity) return;

    let localDateDep = moment(this.activity.flightLeg.departure.dates.local);
    let localDateArr = moment(this.activity.flightLeg.arrival.dates.local);

    let utcDateDep = moment(this.activity.flightLeg.departure.dates.utc);
    let utcDateArr = moment(this.activity.flightLeg.arrival.dates.utc);   

    let brasiliaDateDep = moment(this.activity.flightLeg.departure.dates.brasilia);
    let brasiliaDateArr = moment(this.activity.flightLeg.arrival.dates.brasilia);   

    this.localDateEndsTomorrow = !localDateArr.isSame(localDateDep, 'day');
    this.utcDateEndsTomorrow = !utcDateArr.isSame(utcDateDep, 'day');
    this.brasiliaDateEndsTomorrow = !brasiliaDateArr.isSame(brasiliaDateDep, 'day');
  }

  private fillIncompleteFlight(){
    this.activity.diff = 'Not available';
    this.activity.flightLeg.departure.dates.utc = this.activity.dates.utcStart;
    this.activity.flightLeg.departure.dates.local = this.activity.dates.localStart ? this.activity.dates.localStart : this.activity.dates.utcStart;
    this.activity.flightLeg.departure.dates.brasilia = this.activity.dates.brasilStart;

    this.activity.flightLeg.arrival.dates.utc = this.activity.dates.utcEnd;
    this.activity.flightLeg.arrival.dates.local = this.activity.dates.localEnd ? this.activity.dates.localEnd : this.activity.dates.utcEnd;
    this.activity.flightLeg.arrival.dates.brasilia = this.activity.dates.brasilEnd;
    this.activity.flightLeg.aircraftRegistration = '-';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  findFlightInformation(){
    if (this.activity.isBlocked) return;
    if(this.incompleteFlight){
      Swal.fire({
        icon: 'info',
        title: 'Incomplete flight',
        text: 'Not enough data to get flight information'
      });
      return;
    }
    this.searchFlightInformation.emit();
  }


  goToDispatchFolder(){
    const { flightLeg } = this.activity;
    const queryParams: any = {
      flightNumber : flightLeg.flightNumber,
      tail : flightLeg.aircraftRegistration,
      operator : flightLeg.carrier,
      departureIataCode : flightLeg.departure.iataCode,
      aircraftSubtype : flightLeg.fleet,
      arrivalIataCode : flightLeg.arrival.iataCode,
      carrierOperating : flightLeg.airlineCodes.operating,
      utcDate : flightLeg.departure.dates.utc, // LEG date UTC
      localDate : flightLeg.departure.dates.local,  // LEG date Local
      dateOfFlight: flightLeg.flightDate // add date of flight
    };

    this.router.navigate(["dispatch-folder"],{ queryParams });
  }

  goToFlightReport(){
    this.router.navigate(["reports", this.activity.flightLeg.flightNumber, this.activity.flightLeg.departure.dates.utc, this.activity.flightLeg.carrier, this.activity.flightLeg.fleet, this.activity.flightLeg.aircraftRegistration, this.activity.flightLeg.departure.iataCode, this.activity.flightLeg.arrival.iataCode]);
  }

  toggleCollapsible(){
    this.panelIsOpen = !this.panelIsOpen;

    if (this.panelIsOpen){
      this.expansionPanel.nativeElement.style.maxHeight = this.expansionPanel.nativeElement.scrollHeight + "px";      
    } else {
      this.expansionPanel.nativeElement.style.maxHeight = null;
    } 
  }

}