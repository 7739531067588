<div class="notifications-main">
  <section class="title-main">
    <h3>Notifications</h3>
    <button (click)="closeNotifications()" mat-icon-button color="primary" aria-label="close icon">
      <mat-icon>close</mat-icon>
    </button>
  </section>

  <mat-tab-group [selectedIndex]="0">

    <mat-tab>
      <ng-template matTabLabel>
        <span [matBadge]="'+' + numberNotificationsLS" matBadgeOverlap="false"
          [matBadgeHidden]="numberNotificationsLS == 0" matBadgeSize="small">
          Loadsheet</span>
      </ng-template>
      <ng-template matTabContent>
        <app-notification (onClick)="onClick($event)"
          (reDraw)="this.numberNotificationsLS = countNotificationsUnViewed('loadsheet')"
          [notifications]="notifications" [typeNotification]="'loadsheet'"></app-notification>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <span [matBadge]="'+' + numberNotificationsOFP" matBadgeOverlap="false"
          [matBadgeHidden]="numberNotificationsOFP == 0" matBadgeSize="small">OFP</span>
      </ng-template>
      <ng-template matTabContent>
        <app-notification (onClick)="onClick($event)"
          (reDraw)="this.numberNotificationsOFP = countNotificationsUnViewed('OFPMessage')"
          [notifications]="notifications" [typeNotification]="'OFPMessage'"></app-notification>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="allowTabOperationalEvents">
      <ng-template matTabLabel>
        <span [matBadge]="'+' + numberNotificationsOpEvents" matBadgeOverlap="false"
          [matBadgeHidden]="numberNotificationsOpEvents == 0" matBadgeSize="small">
          Op.Events</span>
      </ng-template>
      <ng-template matTabContent>
        <app-notification (onClick)="onClick($event)"
          (reDraw)="this.numberNotificationsOpEvents = countNotificationsUnViewed('OperationalEvent')"
          [notifications]="notifications" [typeNotification]="'OperationalEvent'"></app-notification>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <span [matBadge]="'+' + numberNotificationsCOMM" matBadgeOverlap="false"
          [matBadgeHidden]="numberNotificationsCOMM == 0" matBadgeSize="small">Communications</span>
      </ng-template>
      <ng-template matTabContent>
        <app-notification-communications (onClick)="onClick($event)"
          (reDraw)="this.numberNotificationsCOMM = countNotificationsUnViewed('communications')"
          [notifications]="notifications" [typeNotification]="'communications'"></app-notification-communications>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <span [matBadge]="'+' + numberNotificationsOthers" matBadgeOverlap="false"
          [matBadgeHidden]="numberNotificationsOthers == 0" matBadgeSize="small">Others</span>
      </ng-template>
      <ng-template matTabContent>
        <app-notification (onClick)="onClick($event)"
          (reDraw)="this.numberNotificationsOthers = countNotificationsUnViewed('custom')"
          [notifications]="notifications" [typeNotification]="'custom'"></app-notification>
      </ng-template>
    </mat-tab>

  </mat-tab-group>
</div>