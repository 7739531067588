import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NewsletterListResponse } from '../../../domain/newsletter-list-response';
import { NewsletterListRequest } from '../../../domain/newsletter-list-request';
import { IProfile } from '../../../domain/user/user';
import { CommunicationType } from '../../../domain/communication-type';
import { MatDialog } from '@angular/material/dialog';
import { NewsletterService } from '../../../services/newsletter.service';
import { UserService } from 'src/app/core/services/user/user.service';
import * as moment from 'moment';
import { NewsletterModalComponent } from '../../modals/newsletter-modal/newsletter-modal.component';
import { CommonsService } from 'src/app/core/services/commons/commons.service';

@Component({
    selector: 'app-view-documents',
    templateUrl: './view-documents.component.html',
    styleUrls: ['./view-documents.component.scss'],
    standalone: false
})
export class ViewDocumentsComponent implements OnInit {

  @Input({ required: true, alias: 'type' }) dataType: { section: string };
  @Output() emitNumberOfNoAck: EventEmitter<number> = new EventEmitter();
  @ViewChild('typeFilterSelected', { static: false }) typeFilterSelected;
  @ViewChild('allFilter', { static: false }) allFilter;

  newsletters: Array<NewsletterListResponse>;
  newsResponse: Array<NewsletterListResponse> = new Array<NewsletterListResponse>();
  newslettersRequest: NewsletterListRequest = new NewsletterListRequest();
  loadingNewsletter: boolean = true;
  dialogViewNewsletter: any;
  profile: IProfile;
  typesNews: Array<CommunicationType> = new Array<CommunicationType>();

  private brazilOperators: string[] = ["JJ", "M3", "PZ"]

  constructor(
    private dialog: MatDialog,
    private newsletterService: NewsletterService,
    private _user: UserService,
    private commonsService: CommonsService
  ) { }

  ngOnInit() {
    let user = this._user.getUserValue;
    this.profile = user?.profile;
    this.loadTypesCommunication();
    this.commonsService.registryEvent({ event: "view_newsletter_section", category: this.dataType.section });
  }

  loadTypesCommunication() {
    this.newsletterService.getTypes(this.dataType?.section).subscribe({
      next: resp => {
        this.typesNews = resp;
        this.verifyLanguage(this.profile["operator"], resp, "combo");
        this.typesNews = [{ id: 0, type: 'All' }].concat(this.typesNews);
        this.typeFilterSelected.value = "All";
        this.loadNewsletters();
      },
      error: err => {
        console.error("Err at getTypes", err);
        this.loadingNewsletter = false;
      }
    });
  }

  loadNewsletters() {

    this.newslettersRequest.fleet = this.profile['fleet'];
    this.newslettersRequest.operator = this.profile['operator'];
    this.newslettersRequest.startDate = moment().format('YYYY-MM-DD');
    this.newslettersRequest.endDate = moment().subtract(365, "days").format('YYYY-MM-DD');

    this.newsletterService.listNewsletter(this.newslettersRequest, this.dataType?.section).subscribe({
      next: (resp: Array<NewsletterListResponse>) => {
        resp = this.sortByPublishDate(resp);
        let count = this.countItemsWithoutAcknowledge(resp);
        this.emitNumberOfNoAck.emit(count);
        this.newsletters = new Array<NewsletterListResponse>();
        this.newsletters = this.verifyLanguage(this.profile["operator"], resp, "newsletter");
        this.newsResponse = this.verifyLanguage(this.profile["operator"], resp, "newsletter");
        this.loadingNewsletter = false;
      },
      error: err => {
        console.error("Err at listNewsletter", err);
        this.loadingNewsletter = false;
      }
    });
  }

  verifyLanguage(operator: string, data, type: string) {
    if (this.brazilOperators.includes(operator)) {
      let key = type == "newsletter"? "typeName" : "type";
      data.forEach(item => {
        if (item.section == "Security") {
          if (item[key] == "Nuestros Casos") item[key] = "Nossos casos"
          if (item[key] == "Informativos de seguridad") item[key] = "Informativo de Segurança"
        }
      });
    } 
    return data;
  }

  openNewsletter(newsletter: NewsletterListResponse) {
    this.commonsService.registryEvent({ event: "view_newsletter_modal", category: this.dataType.section });
    this.dialogViewNewsletter = this.dialog.open(NewsletterModalComponent, {
      maxWidth: '90% !important',
      minWidth: "100%",
      width: "100%",
      height: "90vh",
      data: newsletter,
      panelClass: 'custom-dialog-container'
    });
    this.dialogViewNewsletter.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.loadNewsletters();
      }
    });
  }

  applyFilter() {
    let news;
    if (this.typeFilterSelected.value) {
      news = this.filterType(this.newsResponse);
    }
    this.filterAll(news);
  }

  filterType(objectToFilter) {
    let news = objectToFilter;
    if (this.typeFilterSelected.value != "All") {
      news = objectToFilter.filter(value => {
        return value.typeName == this.typeFilterSelected.value;
      });
    }
    return news;
  }

  filterAll(news) {
    let filteredNames = news.filter(objectNew => {
      return objectNew.title.toLowerCase().indexOf(this.allFilter.nativeElement.value.toLowerCase()) > -1;
    });
    this.newsletters = filteredNames;
  }

  sortByPublishDate(resp): Array<NewsletterListResponse> {
    resp.sort((a, b) => {
      return new Date(b.publishingDate).getTime() - new Date(a.publishingDate).getTime();
    });
    return resp;
  }

  private countItemsWithoutAcknowledge(communications: Array<NewsletterListResponse>) {
    return communications.reduce((acc, obj) => acc + (obj.acknowledge ? 0 : 1), 0);
  }

}
