import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { IndexeddbService } from '../../modules/pilot/services/indexeddb.service';
import { SecurityService } from '../../core/services/security/security.service';
import { CommonsService } from '../../core/services/commons/commons.service';
import { ReportService } from 'src/app/modules/pilot/services/report.service';
import { SafetyEventsService } from '../../modules/pilot/services/safety-events.service';

import * as moment from 'moment';
import { IUser } from 'src/app/modules/pilot/domain/user/user';
import { Device } from '@capacitor/device';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { App } from '@capacitor/app';
import { MatDialog } from '@angular/material/dialog';
import { AqdModalComponent } from '../../modules/pilot/components/modals/aqd-modal/aqd-modal.component';
import { environment } from 'src/environments/environment';
import { ConstantsTools } from 'src/app/modules/tools/commons/constants';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
    standalone: false
})

export class SideNavComponent implements OnInit {

  @Input('locationNav') locationNav: string;
  @Input("statusSendReport") statusSendReport: boolean;
  @Input("user") user: IUser;

  pendingReport: boolean = false;
  showMessageReport: boolean = false;
  safetyAlerts: number;

  month: string;

  reportRequest;
  public route: Promise<any>;
  innerWidth: any;
  version = "";
  hideSection: boolean = this.constants.hideSectionsStores;
  mode = new UntypedFormControl('push');
  VERSION_WEB = this.constants.VERSION_WEB;
  isTestEnvironment: boolean = !environment.production;
  isWeb: boolean = false;
  allowPilotTools: boolean = false;
  
  constructor(
    public securityService: SecurityService,
    public router: Router,
    private commonsService: CommonsService,
    private reportService: ReportService,
    private indexeddb: IndexeddbService,
    private safetyEventsService: SafetyEventsService,
    private constants: PilotConstants,
    private constantsTools: ConstantsTools,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    Device.getInfo().then(info => {
      let { platform } = info;
      if (platform == "web") {
        this.version =  'v.' + this.VERSION_WEB;
        this.isWeb = true;
      } else {
        App.getInfo().then(app => this.version = 'v.' + app.version + `(${app.build})`);
      }
    });
    this.reportService.pendingReport.subscribe(val => {
      this.pendingReport = val;
      this.showMessageReport = val;
    });
    this.innerWidth = window.innerWidth;
    if (this.user?.userName != undefined && this.user?.lastName != undefined) {
      this.user.lastName = this.user.lastName.split(" ")[0];
      this.indexeddb.getDataFromTable('report', 'requestFlightReport').then(value => {
        if (value != undefined) {
          this.pendingReport = true;
          this.showMessageReport = true;
        }
      });

      this.allowPilotTools = this.securityService.isAllowed(this.constantsTools.ROLES_PILOT_TOOLS);
      
    }

    let period = {
      startDate: moment().startOf('month').subtract(12, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    };

    //Safety video notifications
    this.safetyEventsService.getNotifications(period).subscribe(resp => this.safetyAlerts = resp);

    //Current month
    this.month = moment().format('MMMM').toUpperCase();

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.indexeddb.getDataFromTable('report', 'requestFlightReport').then(value => {
      this.reportRequest = value;
      if (this.statusSendReport && this.reportRequest === undefined) {
        this.showMessageReport = false;
      } else if (!this.statusSendReport && this.reportRequest !== undefined) {
        this.showMessageReport = true;
      }
    });
  }

  sendTo(navigate: string) {
    let event: string = "";
    switch (navigate) {
      case "tools": {
        event = "Communications Tools";
        this.router.navigate([navigate]);
        break;
      }
      case "home": {
        event = "open_home_from_side_nav";
        this.router.navigate([navigate]);
        break;
      }
      case "safety": {
        event = "safety_section";
        this.router.navigate([navigate]);
        break;
      }
      case "fuel": {
        event = "efficiency_section";
        this.router.navigate([navigate]);
        break;
      }
      case "reports": {
        event = "flight_report_section";
        this.router.navigate([navigate]);
        break;
      }
      case "newsletter": {
        event = "newsletter_section";
        this.router.navigate([navigate]);
        break;
      }
      case "faq": {
        event = "open-faq";
        this.router.navigate([navigate]);
        break;
      }
      case "str": {
        event = "open_STR";
        break;
      }
      case "e-pass": {
        event = "open_e_pass";
        break;
      }
      case "hoteles-ssc": {
        event = "open_hoteles_ssc";
        break;
      }
      case "hoteles-br": {
        event = "open_hoteles_br";
        break;
      }
      case "AQD": {
        event = "open_aqd_modal";
        this.openAQDModal();
        break;
      }
    }
    this.commonsService.registryEvent({ event, category: "SideNav" });
  }

  openAQDModal() {
    this.dialog.open(AqdModalComponent, {
      minHeight: "455px",
      width: '600px',
      maxWidth: '95vw',
      panelClass: 'custom-dialog-container'
    });
  }

  logOutGoogle() {
    Swal.fire({
      text: 'Are you sure you want to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.commonsService.registryEvent({category:"SideNav", event: "Logout"});
        this.securityService.logOut();
      }
    });
  }
}
